<template>
  <section class="app-ecommerce-details">
    <b-alert
      variant="danger"
      :show="product === undefined"
    >
      <h4 class="alert-heading">
        Error fetching product data
      </h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-e-commerce-shop' }"
        >
          Shop
        </b-link>
        for other items.
      </div>
    </b-alert>
    <!-- Content -->
    <span
      class="link"
      @click="back"
    > Назад </span>
    <b-card
      v-if="product"
      class="mt-2"
      no-body
    >
      <b-card-body>
        <b-row class="my-2">
          <b-col
            cols="12"
            md="6"
          >
            <b-button
              size="sm"
              variant="outline-primary mr-1"
              class="link mb-2"
              @click="imageModal = true"
            >
              <feather-icon
                icon="CameraIcon"
                size="12"
              />
              Изменить фото
            </b-button>
            <div
              v-if="mainPhoto"
              class="text-center"
            >
              <el-image
                fluid
                class="product-img"
                :src="storageUrl + mainPhoto.url"
                :preview-src-list="previewSrcList"
              />
              <list-view
                v-if="product.photos && product.photos.length"
                class="w-100"
                :photos="previewSrcList"
              />
            </div>
            <div v-else>
              <b-img
                src="@/assets/images/avatars/product.jpg"
                :alt="`Image of ${product.name_uz}`"
                class="product-img"
                fluid
              />
            </div>
          </b-col>
          <!-- Right: Product Details -->
          <b-col
            cols="12"
            md="6"
          >
            <p class="fs14">
              {{
                product.parent0_name_uz +
                  "/" +
                  product.parent1_name_uz +
                  "/" +
                  product.parent2_name_uz +
                  "/"
              }}
            </p>
            <h4>{{ product.name_uz }}</h4>
            <!-- Price And Ratings -->
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h4 class="item-price mr-1">
                ${{ product.price_dollar }}
              </h4>
              <span class="unstyled-list list-inline pl-1 border-left">
                Цена продажа
              </span>
            </div>
            <!-- Price And Ratings -->
            <div
              v-if="!['marketer'].includes(user.role.name)"
              class="ecommerce-details-price d-flex flex-wrap mt-1"
            >
              <h4 class="item-price mr-1">
                ${{ product.cost_dollar }}
              </h4>
              <span class="unstyled-list list-inline pl-1 border-left">
                Цена прихода
              </span>
            </div>
            <!-- Price And Ratings -->
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h4 class="item-price mr-1">
                ${{ product.price_dollar_installment }}
              </h4>
              <span class="unstyled-list list-inline pl-1 border-left">
                Цена рассрочка
              </span>
            </div>
            <hr>
            <div v-if="!isEdit">
              <b-button
                variant="outline-primary mr-1"
                class="float-right"
                @click="openEditor()"
              >
                <feather-icon
                  icon="EditIcon"
                  size="12"
                />
                Tahrirlash
              </b-button>
              <div
                v-for="(characters, index) in characteristicsInfoList"
                :key="index"
              >
                <div v-if="characters.is_description">
                  <h1>Tavsifi</h1>
                  <div
                    class="description-show mt-2"
                    v-html="characters.value"
                  />
                </div>
              </div>
              <hr>
              <h1>Xarakteristika</h1>
              <div
                v-for="(characters, index) in characteristicsInfoList"
                :key="index + 99"
              >
                <div v-if="!characters.is_description">
                  <div class="d-flex fs18 mt-1">
                    <b class="">
                      {{ characters.product_category_characteristic.name_uz }}:
                    </b>
                    <span class="ml-1">
                      {{ characters.value }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <Desciption
              v-if="isEdit"
              :characteristics-info-list="characteristicsInfoList"
              @save="saveDescription"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <ImageUpload
      v-if="imageModal"
      :uploaded-photos="product.photos"
      :product-name="product.name_uz"
      @close="closeUpload"
    />
  </section>
</template>

<script>
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import { ref, computed } from '@vue/composition-api'
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BLink,
  BAlert,
} from 'bootstrap-vue'
import ListView from './ListView.vue'
import Desciption from './description.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BLink,
    BAlert,
    ListView,
    Desciption,
    ImageUpload: () => import('@/components/modals/imagesUplad.vue'),
  },
  setup() {
    const product = ref(null)
    const mainPhoto = ref(null)
    const imageModal = ref(false)
    const isEdit = ref(false)
    const { route } = useRouter()
    const productId = route.value.params.id
    const fetchProduct = () => {
      store
        .dispatch('productCategory/show', {
          id: productId,
          relations: 'photos',
        })
        .then(response => {
          product.value = response.data
          if (response.data.photos && response.data.photos.length) {
            if (response.data.photos.find(p => p.is_main == 1)) {
              mainPhoto.value = response.data.photos.find(
                p => p.is_main == 1,
              )
            }
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            product.value = undefined
          }
        })
    }
    const selectedColor = ref(null)
    const characteristicsInfoList = ref(null)
    fetchProduct()
    const fetchDescription = () => {
      store
        .dispatch('productCategory/getCharacteristicsValue', {
          product_category_id: productId,
          relations: 'productCategoryCharacteristic',
        })
        .then(response => {
          characteristicsInfoList.value = response.data.data
          if (!characteristicsInfoList.value.length) {
            isEdit.value = true
          }
        })
    }
    fetchDescription()
    const storageUrl = `${process.env.VUE_APP_BASE_URL}storage/`
    const openEditor = () => {
      isEdit.value = true
    }
    const saveDescription = () => {
      fetchDescription()
      isEdit.value = false
    }
    const closeUpload = () => {
      imageModal.value = false
      fetchProduct()
    }
    const previewSrcList = computed(() => product.value?.photos.map(p => storageUrl + p.url))
    return {
      product,
      imageModal,
      isEdit,
      storageUrl,
      mainPhoto,
      selectedColor,
      characteristicsInfoList,
      previewSrcList,
      openEditor,
      saveDescription,
      closeUpload,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
.description-show {
  border: 1px solid #eee;
  border-radius: 15px;
  padding: 10px;
}
</style>
