<template>
  <b-card-body>
    <swiper
      class="swiper-responsive-breakpoints px-4 py-2"
      :options="swiperOptions"
    >
      <swiper-slide
        v-for="(photo,index) in photos"
        :key="index"
      >
        <div class="img-container h150 mx-auto py-75">
          <!-- <b-img
            :src="storageUrl+photo.url"
            fluid
          /> -->
          <el-image
            :src="photo"
            :preview-src-list="photos"
          />
        </div>
      </swiper-slide>

      <!-- Add Arrows -->
      <div
        slot="button-next"
        class="swiper-button-next"
      />
      <div
        slot="button-prev"
        class="swiper-button-prev"
      />
    </swiper>
  </b-card-body>
</template>

<script>
import {
  BCardBody,
} from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    BCardBody,
    Swiper,
    SwiperSlide,
  },
  props: {
    photos: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const swiperOptions = {
      slidesPerView: 3,
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1600: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
        1300: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
      },
    }

    /* eslint-disable global-require */
    const relatedProducts = [
      {
        image: require('@/assets/images/elements/apple-watch.png'),
      },
      {
        image: require('@/assets/images/elements/macbook-pro.png'),
      },
      {
        image: require('@/assets/images/elements/homepod.png'),
      },
      {
        image: require('@/assets/images/elements/magic-mouse.png'),
      },
      {
        image: require('@/assets/images/elements/iphone-x.png'),
      },
    ]
    const storageUrl = `${process.env.VUE_APP_BASE_URL}storage/`

    return {
      swiperOptions,
      relatedProducts,
      storageUrl,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';
</style>
